import * as c from '../constants/departments';
import { RESET_ALL_STORE } from '../constants/user';

const initialState = {
  departments: [],            // 診療科目配列
  departmentsMap: new Map(),  // 診療科目マップ(キー:科目ID(数値) 値:日本語科目名)
  error: null,
};

export default function departments(state = initialState, action) {
  switch (action.type) {
    case c.GET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload.departments,
        departmentsMap: action.payload.departmentsMap,
      };

    // すべてリセットする
    case RESET_ALL_STORE:
      return initialState;

    default:
      return state;
  }
}