import * as c from '../constants/departments';

/**
 * 診療科目一覧の取得成功をreducerへ通知する
 * @param {object} items - 項目オブジェクト
 */
export function successGetDepartments(items) {
  return {
    type: c.GET_DEPARTMENTS,
    payload: {
      departments: items.departments,
      departmentsMap: items.departmentsMap,
    }
  };
}

/**
 * 診療科目一覧の取得失敗をreducerへ通知する
 * @param {string} message - エラーメッセージ文字列
 */
export function failureGetDepartments(message) {
  return {
    type: c.GET_DEPARTMENTS,
    payload: {
      message
    },
    error: true,
  };
}
