import React from 'react';
import styles from './loadingMask.module.css';

/**
 * ローディングマスク
 * 表示中は他の操作ができなくなる
 *
 * @param {object} groups - グループストアオブジェクト
 * @param {object} threads - スレッドストアオブジェクト
 * @returns {null|*}
 */
function LoadingMask({ groups, threads }) {
  const requests = [
    groups.requestGetGroupUsers,
    groups.requestGetGroupRequests,
    threads.requestGetGroupThreads,
    threads.requestGetLikedThreads,
    threads.requestGetUnsolvedThreads,
    threads.requestGetFilteredThreads,
    threads.requestGetSearchedThreads,
    threads.requestGetKnowledgeThreads,
    threads.requestGetAnsweredThreads,
    threads.requestGetAskedThreads,
    threads.requestGetSharedThreads,
  ];

  if (
    requests.some(value => value === true)
  ) {
    return (
      <div className={styles.wrapper}>
        <img className={styles.loading} src="/images/loading.gif" alt="loading..."/>
      </div>
    );
  }
  return null;
}

export default LoadingMask;
