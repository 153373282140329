import ConfirmModal from './ConfirmModal';
import { closeConfirmModal } from '../../../actions/ui';
import { connect } from 'react-redux';

function mapStateToProps({ ui }) {
  return {
    confirmModal: ui.confirmModal
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onClose() {
      dispatch(closeConfirmModal());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);