import React from 'react';
import * as stringUtil from '../../utils/string';
import { useLocation } from 'react-router-dom';
import FlashMessage from '../FlashMessage';

/**
 * メールアドレス確認完了メッセージ
 *
 */
function EmailVerifiedMessage() {
  const isToAppPath = location.pathname.match('toapp') ? true : false;
  const locate = useLocation();
  const signUpParam = stringUtil.getParamValue('emailVerified', locate.search);
  const text = 'メールアドレスの確認が取れました。';

  if (isToAppPath && signUpParam == 'true') {
    return <FlashMessage text={text} />;
  } else {
    return null;
  }
}

export default EmailVerifiedMessage;
