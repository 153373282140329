import { getUserIdToken } from './auth';
import { parseToUnixTime } from './string';
import { User } from '../constants/status';
import { fetchUserStatus } from '../services/auth';
import { setStatus } from '../actions/register';
import { STATUS } from '../constants/status';
import store from '../store';

const baseHost = process.env.REACT_APP_API_BASE_HOST;
const baseGroup = process.env.REACT_APP_API_BASE_GROUP;
const baseVersion = process.env.REACT_APP_API_BASE_VERSION;

/**
 * アクセストークン
 */
export const getToken = async () => {
  const token = await getUserIdToken();
  return token;
};

/**
 * ベースURL
 *
 * 定義は .env.{development|test|production} を参照
 *
 * @param {string} group - グループ名(ex: 'public')
 * @param {string} version - バージョン(ex: 'v1.0')
 */
export const baseURL = (group = baseGroup, version = baseVersion) => {
  return baseHost + group + '/' + version + '/';
};

const json = async (method = 'GET', body = null, auth = true) => {
  const token = auth ? await getUserIdToken() : null;

  const init = {
    method: method,
    mode: 'cors',
    headers: {
      Authorization: 'Bearer ' + token,
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
    },
  };

  return body ? { ...init, body: JSON.stringify(body) } : init;
};

const image = async () => {
  const token = await getUserIdToken();

  return {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: 'Bearer ' + token,
      Accept: 'image/*',
    },
  };
};

const multipart = async (formData, method = 'POST') => {
  const token = await getUserIdToken();

  return {
    method: method,
    mode: 'cors',
    headers: {
      Authorization: 'Bearer ' + token,
      Accept: 'application/json',
      /*
       * Content-Type は明示的に指定しない。
       * 送信時に自動で boundary 文字列が付加されないと
       * サーバー側で受け取れないため。
       */
      //'Content-Type': 'multipart/form-data',
    },
    body: formData,
  };
};

/**
 * リクエストオプション
 */
export const option = {
  json,
  image,
  multipart,
};

/**
 * レスポンス成功の判定
 *
 * 引数に渡したオブジェクトから、正しいレスポンスであるかを判定する。
 * 成功時にはオブジェクトを JSON パースした値を、そうでない場合には
 * エラーをスローする。
 *
 * @param response
 * @returns {*}
 */
export const isSuccess = (response) => {
  return response.ok
    ? response.json()
    : response
        .json()
        .then((error) => {
          /* Errorオブジェクトの引数には文字列しか渡せない */
          throw Error(error.resultInfo.messageInfo.message);
        })
        .catch((error) => {
          /* JSONのパースに失敗した時のため */
          throw Error(error.message);
        });
};

/**
 * 作成日付の追加
 *
 * @param {object} article - 質問,回答,返信
 * @param {string} article.createAt - 作成日付 ISO8601形式(例:'2019-07-16T11:49:24+09:00')
 * @returns {{date: *}} - 日付を付加したオブジェクト
 */
export const addCreateAtDate = (article) => {
  if (!article) {
    return article;
  }
  return {
    ...article,
    date: parseToUnixTime(article.createAt),
  };
};

/**
 * fetchメソッドのラッパーメソッド
 * 
 * @param {string} url - リクエスト先のURL
 * @param {string} op - リクエストオプション
 * @param {boolean} skipJudgeForbidden - 権限エラーの時ユーザーステータスを取得をスキップするフラグ
 * @returns {Promise} リクエストしたレスポンス
 */
export const fetchData = (url, op, skipJudgeForbidden = false) => {
  return fetch(url, op)
    .then(async (response) => {
        if (!skipJudgeForbidden && response.status === 403) {
          const body = await response.json();
          if (body) {
            const isPermissionError =
            body['resultInfo']['messageInfo']['details'].length > 0 &&
            body['resultInfo']['messageInfo']['details'].some(
              (detail) => detail['id'] === 'E10160008',
            );
            if (isPermissionError) {
              const userStatus = await fetchUserStatus();
              if (userStatus == User.status.BAN || userStatus == User.status.EXIT || userStatus == User.status.EXPIRE) {
                // statusを変更し、退会済み画面へ遷移させる
                store.dispatch(setStatus(STATUS.EXITED_USER));
              }
            }
          }
        }
      return response;
    });
};
