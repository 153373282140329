import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../reducers';

const middleware = [thunk];
if(process.env.REACT_APP_REDUX_LOGGER === 'true') {
  const { createLogger } = require('redux-logger');
  const logger = createLogger({
    collapsed: true,
    diff: true,
  });
  middleware.push(logger);
}
const store = createStore(
  reducers,
  applyMiddleware(...middleware),
);

export default store;
