import React from 'react';
import styles from './flashMessage.module.css';

/**
 * フラッシュメッセージ
 *
 * @param {string} text - 表示テキスト
 */
function FlashMessage({ text }) {
  if (text == '') {
    return null;
  }
  return <div className={styles.wrapper}>{text}</div>;
}

export default FlashMessage;
