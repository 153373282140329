import React from 'react';
import styles from './messageBoard.module.css';

/**
 * メッセージを表示させるための長方形のボード
 * 
 * @reactProps {string} text - 表示テキスト
 */
class MessageBoard extends React.Component {
    render() {
        return (
            <div className={styles.content}>
                <div className={styles.message}>
                  {this.props.text}
                </div>
            </div>
        );
    }
}

export default MessageBoard;
