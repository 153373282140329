import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import Signin from '../Signin';
import Signup from '../Signup';
import ResetPassword from '../ResetPassword';
import Area from '../Area';
import NotFound from '../NotFound';
import Loading from '../Loading';
import LoadingMask from '../LoadingMask';
import { firebase } from '../../firebase';
import styles from './app.module.css';
import { STATUS } from '../../constants/status';
import * as privacyPolicyConst from '../../constants/privacyPolicyAgreements';
import * as termsOfServiceConst from '../../constants/termsOfServiceAgreements';
import { sendAnalyticsPageView } from '../../utils/analytics';
import AsyncContainer from '../../utils/AsyncContainer/AsyncContainer';
import ConfirmModal from '../Modal/ConfirmModal';
import ProfileUpdateModal from '../Modal/ProfileUpdateModal';
import ProfileHospitalUpdateModal from '../Modal/ProfileHospitalUpdateModal';
import ProfileDepartmentUpdateModal from '../Modal/ProfileDepartmentUpdateModal';
import OssLicense from '../OssLicense';
import { User } from '../../constants/status';
import ExitedUserScreen from '../ExitedUserScreen';
// TODO: メール確認画面表示をフローの後方に修正時に利用。
// import EmailVerifiedRemindText from '../EmailVerifiedRemindText';
import EmailVerifiedMessage from '../EmailVerifiedMessage';
import MobileAppInstallBanner from '../Banner/MobileAppInstallBanner';

/*
 * スクリプトファイルの分割
 * コンポーネントを描画の際に読みこむ
 */
const TermsOfServiceAgreement = AsyncContainer(() =>
  import('../TermsOfServiceAgreement').then((module) => module.default),
);
const PrivacyPolicyAgreement = AsyncContainer(() =>
  import('../PrivacyPolicyAgreement').then((module) => module.default),
);
const ConfirmEmail = AsyncContainer(() =>
  import('../ConfirmEmail').then((module) => module.default),
);
const ProfileEmailEdit = AsyncContainer(() =>
  import('../ProfileEmailEdit').then((module) => module.default),
);
const JobChoice = AsyncContainer(() =>
  import('../JobChoice').then((module) => module.default),
);
const RegisterRequestByNumber = AsyncContainer(() =>
  import('../RegisterRequestByNumber').then((module) => module.default),
);
const RegisterRequestByPhoto = AsyncContainer(() =>
  import('../RegisterRequestByPhoto').then((module) => module.default),
);
const RegisterRequestEditByPhoto = AsyncContainer(() =>
  import('../RegisterRequestEditByPhoto').then((module) => module.default),
);
const RegisterRequestEditByNumber = AsyncContainer(() =>
  import('../RegisterRequestEditByNumber').then((module) => module.default),
);
const ConfirmLicense = AsyncContainer(() =>
  import('../ConfirmLicense').then((module) => module.default),
);
const Routes = AsyncContainer(() =>
  import('../Routes').then((module) => module.default),
);

const RedirectToTop = () => <Redirect to="/" />;

/**
 * アプリケーションのルートコンポーネント
 * ログインの判定や未ログイン時のルーティングも行う
 *
 * @reactProps {object} auth - store
 * @reactProps {object} register - store
 * @reactProps {function} setDiscloseId - 外部向けIDの設定
 * @reactProps {function} getAuthUser - ログインユーザーの取得
 * @reactProps {function} setStatus - 新規登録の進捗ステータスをStoreにセットする
 * @reactProps {function} setUid - ユーザーのidをStoreにセットする
 */
class App extends React.Component {
  async componentDidMount() {
    try {
      const result = await firebase.auth().getRedirectResult();
      if (result.credential && result.additionalUserInfo.isNewUser) {
        // 仮ユーザー作成
        await this.props.setPreRegister();
      }
    } catch (error) {
      this.props.setSocialLoginError(error);
    }

    firebase.auth().onAuthStateChanged((user) => this.setStatus(user));

    // Service Workerが残存していればunregisterする。
    // 経緯：
    // Service Workerの副作用により古いキャッシュで動作してしまう問題があった。
    // そこでService Workerの利用停止を判断した。
    // しかし、既にWorkerがregister済みの環境があるため、unregisterするコードを残しておく必要が出た。
    if (
      'serviceWorker' in navigator &&
      'getRegistrations' in navigator.serviceWorker
    ) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }
  }

  /**
   * ルーティングによりパスが切り替わったら画面最上部にスクロールさせる
   * （そうしないとスクロールが残ったまま画面遷移してしまうため）
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);

      /* アナリティクス情報の送信 */
      sendAnalyticsPageView(this.props.location.pathname);
    }
  }

  async setStatus(user) {
    const status = await this.checkStatus(user);
    this.props.setUid(user ? user.uid : null);
    this.props.setStatus(status);
    this.props.setUserStatus();
  }

  /**
   * ユーザーの登録状態をチェックする
   *
   */
  async checkStatus(user) {
    if (user === undefined || user === null) {
      return STATUS.SIGNUP;
    }

    // Facebook/Appleによる登録の場合はEmail確認を飛ばす
    const isFacebook = user.providerData[0].providerId === 'facebook.com';
    const isApple = user.providerData[0].providerId === 'apple.com';
    if (!isFacebook && !isApple && user.emailVerified === false) {
      return STATUS.VERIFY_EMAIL;
    }

    await this.props.setDiscloseId();

    if (!this.props.auth.discloseId) {
      await this.props.setUserStatus();
      const { userStatus } = this.props.auth.user;
      if (
        userStatus === User.status.BAN ||
        userStatus === User.status.EXIT ||
        userStatus === User.status.EXPIRE
      ) {
        return STATUS.EXITED_USER;
      }
      return STATUS.REGISTER_REQUEST;
    }

    await this.props.getRegisterRequest();
    const requestInfo = this.props.register.requestInfo;

    if (
      requestInfo === null ||
      requestInfo.userStatus === User.status.CREATED
    ) {
      return STATUS.REGISTER_REQUEST;
    }

    if (requestInfo.userStatus === User.status.REVIEWING) {
      return STATUS.CONFIRM_LICENSE;
    }
    if (requestInfo.userStatus === User.status.INVALID) {
      if (
        requestInfo.licenseNumber == null &&
        requestInfo.licensePhotoUrls[1] == null
      ) {
        /* 既存フローにて画像申請でNGになったユーザー用の判定(医師/医学生選択からやり直し)
           licensePhotoUrls[1] === null のチェックを行っている理由は、
           既存フローではライセンス画像が1枚しかアップロードできず、新フローでは2枚のアップロードが必須なので、
           licensePhotoUrls[1] === null であれば旧フローと判定できるため。
        */
        return STATUS.REGISTER_REQUEST;
      } else {
        return STATUS.INVALID_LICENSE;
      }
    }

    if (requestInfo.userStatus === User.status.OK) {
      const termsOfServiceAgreements = await this.props.getTermsOfServiceAgreements();
      if (
        termsOfServiceAgreements.payload.agreements.hasNeedAgreeVersions &&
        termsOfServiceAgreements.payload.agreements.needAgreeVersions.includes(
          termsOfServiceConst.REQUIRED_VERSION,
        )
      ) {
        return STATUS.TERMS_OF_SERVICE;
      }

      const privacyPolicyAgreements = await this.props.getPrivacyPolicyAgreements();
      if (
        privacyPolicyAgreements.payload.agreements.hasNeedAgreeVersions &&
        privacyPolicyAgreements.payload.agreements.needAgreeVersions.includes(
          privacyPolicyConst.REQUIRED_VERSION,
        )
      ) {
        return STATUS.PRIVACY_POLICY;
      }

      return STATUS.REGISTERED;
    }
    // システム不具合などでどの条件にも当てはまらない場合を考慮
    return STATUS.REGISTER_REQUEST;
  }

  render() {
    const { status } = this.props.register;
    let userStatus = null;
    if (this.props.auth.user) {
      userStatus = this.props.auth.user.userStatus;
    }

    if (status === null) {
      return <Loading type="wrap" />;
    }

    //利用規約同意画面
    if (status === STATUS.TERMS_OF_SERVICE) {
      return (
        <div className={styles.wrapper}>
          <Header />
          <TermsOfServiceAgreement />
          <Footer />
          <ConfirmModal />
          <MobileAppInstallBanner />
        </div>
      );
    }

    //プラポリ同意画面
    if (status === STATUS.PRIVACY_POLICY) {
      return (
        <div className={styles.wrapper}>
          <Header />
          <PrivacyPolicyAgreement />
          <Footer />
          <ConfirmModal />
          <MobileAppInstallBanner />
        </div>
      );
    }

    // ログイン or 新規登録画面へ
    if (status === STATUS.SIGNUP) {
      return (
        <div className={styles.wrapper}>
          <Header />
          <Switch>
            <Route exact={true} path="/" component={Signin} />
            <Route exact={true} path="/signup" component={Signup} />
            <Route exact={true} path="/login" component={RedirectToTop} />
            <Route
              exact={true}
              path="/resetPassword"
              component={ResetPassword}
            />
            <Route exact={true} path="/threadId/:threadId" component={Signin} />
            <Route exact={true} path="/group/:groupId" component={Signin} />
            <Route
              exact={true}
              path="/group/:groupId/post"
              component={Signin}
            />
            <Route exact={true} path="/profile/editEmail" component={Signin} />
            <Route exact={true} path="/profile/self/edit" component={Signin} />
            <Route exact={true} path="/profile/:uid" component={Signin} />
            <Route
              exact={true}
              path="/stream/contents/:contentId"
              component={Signin}
            />
            <Route exact={true} path="/stream/contents" component={Signin} />
            <Route exact={true} path="/auth/redirect" component={Signin} />
            <Route exact={true} path="/oss/qa-app" component={OssLicense} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
          <ConfirmModal />
          <MobileAppInstallBanner />
        </div>
      );
    }

    // メール確認画面へ
    if (status === STATUS.VERIFY_EMAIL) {
      if (userStatus === User.status.OK) {
        return (
          <div className={styles.wrapper}>
            <Header />
            <ProfileEmailEdit />
            <Footer />
            <ConfirmModal />
            <MobileAppInstallBanner />
          </div>
        );
      } else {
        return (
          <div className={styles.wrapper}>
            <Header />
            <ConfirmEmail />
            <Footer />
            <ConfirmModal />
            <MobileAppInstallBanner />
          </div>
        );
      }
    }

    // 医師/医学生選択画面へ
    if (status === STATUS.REGISTER_REQUEST) {
      return (
        <div className={styles.wrapper}>
          <EmailVerifiedMessage />
          <Header />
          <Switch>
            <Route exact={true} path="/signup" component={RedirectToTop} />
            <Route exact={true} path="/toapp" component={JobChoice} />
            <Route
              exact={true}
              path="/license/photo"
              component={RegisterRequestByPhoto}
            />
            <Route
              exact={true}
              path="/license/number"
              component={RegisterRequestByNumber}
            />
            <Route path="/" component={JobChoice} />
          </Switch>
          <Footer />
          <ConfirmModal />
          <MobileAppInstallBanner />
        </div>
      );
    }

    // 審査画面へ
    if (status === STATUS.CONFIRM_LICENSE) {
      return (
        <div className={styles.wrapper}>
          <Header />
          <Area>
            <Switch>
              <Route exact={true} path="/license" component={ConfirmLicense} />
              <Route
                exact={true}
                path="/license/choice"
                component={JobChoice}
              />
              <Route
                exact={true}
                path="/license/photo"
                component={RegisterRequestEditByPhoto}
              />
              <Route
                exact={true}
                path="/license/number"
                component={RegisterRequestEditByNumber}
              />
              <Route path="/" component={ConfirmLicense} />
            </Switch>
          </Area>
          <Footer />
          <ConfirmModal />
          <MobileAppInstallBanner />
        </div>
      );
    }

    // 審査否認画面へ
    if (status === STATUS.INVALID_LICENSE) {
      return (
        <div className={styles.wrapper}>
          <Header />
          <Area>
            <Switch>
              <Route exact={true} path="/license" component={ConfirmLicense} />
              <Route
                exact={true}
                path="/license/choice"
                component={JobChoice}
              />
              <Route
                exact={true}
                path="/license/photo"
                component={RegisterRequestEditByPhoto}
              />
              <Route
                exact={true}
                path="/license/number"
                component={RegisterRequestEditByNumber}
              />
              <Route path="/" component={ConfirmLicense} />
            </Switch>
          </Area>
          <Footer />
          <ConfirmModal />
          <MobileAppInstallBanner />
        </div>
      );
    }

    // 退会済み画面へ
    if (status === STATUS.EXITED_USER) {
      return (
        <div className={styles.wrapper}>
          <Header />
          <ExitedUserScreen />
          <Footer />
          <ConfirmModal />
          <MobileAppInstallBanner />
        </div>
      );
    }

    // サービス画面へ
    return (
      <div className={styles.wrapper}>
        <Routes location={this.props.location} />
        <Footer />
        <LoadingMask />
        <ConfirmModal />
        <ProfileDepartmentUpdateModal />
        <ProfileHospitalUpdateModal />
        <ProfileUpdateModal />
        <MobileAppInstallBanner />
      </div>
    );
  }
}

export default App;
