import { connect } from 'react-redux';
import Header from './Header';
import { getUser } from '../../services/user';
import { resetAllStore } from '../../actions/auth';
import * as ui from '../../actions/ui';

function mapStateToProps({ auth, register }) {
  return {
    user: auth.user,
    status: register.status,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    async getUser(id) {
      dispatch(await getUser(id));
    },
    openPostModal() {
      dispatch(ui.openPostModal());
    },
    closePostModal() {
      dispatch(ui.closePostModal());
    },
    confirm(message, onOK) {
      dispatch(ui.openConfirmModal(message, onOK));
    },
    resetAllStore() {
      dispatch(resetAllStore());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
