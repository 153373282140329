import * as c from '../constants/replies';
import { RESET_ALL_STORE } from '../constants/user';

const initialState = {
  isPostingReply: {},  // リプライ投稿リクエスト中かどうか
  replies: {},         // リプライ一覧
  reply: {},           // 入力中のリプライ項目オブジェクト
  errors: [],          // バリデーションエラーコード配列
  error: null,         // アプリケーションエラーメッセージ文字列
};

export default function replies(state = initialState, action) {
  switch (action.type) {
    // リプライ一覧を取得する
    case c.GET_REPLIES:
      if (action.error) {
        return {
          ...state,
          error: action.payload.error,
        };
      }
      return {
        ...state,
        replies: action.payload.replies,
        error: null,
      };

    // 次のリプライ一覧を取得する
    case c.GET_REPLIES_WITH_CURSOR:
      if (action.error) {
        return {
          ...state,
          error: action.payload.error,
        };
      }
      return {
        ...state,
        replies: {
          ...state.replies,
          ...action.payload.replies,
        },
        error: null,
      };

    // リプライフォームに入力した項目を保存する
    case c.INPUT_REPLY_FORM:
      return {
        ...state,
        reply: {
          ...state.reply,
          [action.payload.messageId]: {
            ...state.reply[action.payload.messageId],
            [action.payload.key]: action.payload.value,
          }
        }
      };

    // 返信投稿の入力項目を検査する
    case c.VALIDATE_POST_REPLY:
      if(action.payload.errors.length > 0) {
        return {
          ...state,
          reply: {
            ...state.reply,
            [action.payload.messageId]: {
              ...state.reply[action.payload.messageId],
              errors: action.payload.errors,
              error: null,
            }
          },
          isPostingReply: {
            ...state.isPostingReply,
            [action.payload.messageId]: false
          },
        };
      }
      return {
        ...state,
        reply: {
          ...state.reply,
          [action.payload.messageId]: {
            ...state.reply[action.payload.messageId],
            errors: [],
          }
        },
      };

    // リプライの投稿リクエストを開始する
    case c.REQUEST_POST_REPLY:
      return {
        ...state,
        isPostingReply: {
          ...state.isPostingReply,
          [action.payload.messageId]: true
        },
      };

    // リプライの投稿リクエストが完了
    case c.POST_REPLY:
      if (action.error) {
        return {
          ...state,
          reply: {
            ...state.reply,
            [action.payload.messageId]: {
              ...state.reply[action.payload.messageId],
              error: action.payload.message,
            }
          },
          isPostingReply: {
            ...state.isPostingReply,
            [action.payload.messageId]: false
          },
        };
      }
      return {
        ...state,
        reply: {
          ...state.reply,
          [action.payload.messageId]: {
            text: '',
            files: null,
            errors: [],
          }
        },
        isPostingReply: {
          ...state.isPostingReply,
          [action.payload.messageId]: false
        },
      };

    // 返信編集の入力項目を検査する
    case c.VALIDATE_EDIT_REPLY:
      if(action.payload.errors.length > 0) {
        return {
          ...state,
          replies: addError(state.replies, action.payload),
        };
      }
      return {
        ...state,
      };

    // 返信の編集
    case c.EDIT_REPLY:
      if(action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      return {
        ...state,
      };

    // すべてリセットする
    case RESET_ALL_STORE:
      return initialState;

    // リプライ削除リクエストの開始
    case c.REQUEST_DELETE_REPLY:
      return {
        ...state,
      };

    // リプライ削除リクエストの完了
    case c.DELETE_REPLY:
      if (action.error) {
        return {
          ...state,
          error: action.payload.message,
        };
      }
      return {
        ...state,
      };

    default:
      return state;
  }
}

/**
 * エラーの追加
 *
 * 更新した返信オブジェクトに、エラーコード配列を追加して
 * 返却する。
 *
 * @param {object} states - ストアにある返信一覧
 * @param {object} payload - ペイロード
 * @returns {object} 新しい返信の配列
 */
const addError = (states, payload) => {
  if(!Object.keys(states).length) {
    return states;
  }

  const replies = states[payload.answerId];
  const update = replies.map(reply => {
    if(reply.replyId === payload.replyId) {
      return {
        ...reply,
        errors: payload.errors,
        error: null,
      };
    }
    return reply;
  });

  return {
    ...states,
    [payload.answerId]: [
      ...update,
    ]
  };
};
