import { connect } from 'react-redux';
import Signin from './Signin';
import { setPreRegister } from '../../services/auth';
import { setSocialLoginError } from '../../actions/auth';

function mapStateToProps({ auth }) {
  return {
    auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSocialLoginError(error) {
      dispatch(setSocialLoginError(error));
    },
    setPreRegister() {
      return dispatch(setPreRegister());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
