import { connect } from 'react-redux';
import App from './App';
import { setStatus } from '../../actions/register';
import { setSocialLoginError, setUid } from '../../actions/auth';
import {
  getAuthUser,
  setDiscloseId,
  setUserStatus,
  setPreRegister,
} from '../../services/auth';
import { getRegisterRequest } from '../../services/register';
import { getPrivacyPolicyAgreements } from '../../services/privacyPolicyAgreements';
import { getTermsOfServiceAgreements } from '../../services/termsOfServiceAgreements';

function mapStateToProps({ auth, register }) {
  return {
    auth,
    register,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setStatus(status) {
      dispatch(setStatus(status));
    },
    setSocialLoginError(error) {
      dispatch(setSocialLoginError(error));
    },
    setUid(id) {
      dispatch(setUid(id));
    },
    setDiscloseId() {
      return dispatch(setDiscloseId());
    },
    getRegisterRequest() {
      return dispatch(getRegisterRequest());
    },
    getAuthUser(discloseId) {
      return dispatch(getAuthUser(discloseId));
    },
    setUserStatus() {
      return dispatch(setUserStatus());
    },
    setPreRegister() {
      return dispatch(setPreRegister());
    },
    getPrivacyPolicyAgreements() {
      return dispatch(getPrivacyPolicyAgreements());
    },
    getTermsOfServiceAgreements() {
      return dispatch(getTermsOfServiceAgreements());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
