import loadImage from 'blueimp-load-image';
import { captureException } from './sentry';

/**
 * バイナリをファイルに変換する
 *
 * 画像fileを渡し、リサイズして返却する
 * HTMLCanvasElement.toDataURL の仕様で data:, が先頭に付きます
 *
 * @param {string} dataUrl e.g. data:image/jpeg;base64,iVBORw0KGgoA...
 * @param {string} format 画像フォーマット e.g. jpeg
 * @param {string} name 画像ファイル名
 * @return {File} 画像ファイルオブジェクト
 */
const toFile = (dataUrl, format, name) => {
  const base64 = dataUrl.replace(/^.*,/, '');
  const bin = atob(base64);
  // バイナリデータ化
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  // ファイルオブジェクト生成
  let file;
  // IEの場合Blobを使用する
  if (!navigator.msSaveBlob) {
    file = new File([buffer.buffer], name, { type: format });
  } else {
    file = new Blob([buffer.buffer], { type: format });
    file.lastModifiedDate = new Date();
    file.name = name;
  }
  return file;
};

/**
 * 画像のリサイズ
 *
 * 画像fileを渡し、リサイズして返却する
 *
 * @param {File} file ファイルオブジェクト
 * @param {Object} fileSize ファイルサイズ
 * @param {number} maxSize ファイルサイズ上限
 * @returns {File} ファイルオブジェクト
 */
const resizeImage = (file, fileSize, maxSize) => {
  return new Promise((resolve, reject) => {
    loadImage.parseMetaData(file, (data) => {
      let options = '';
      if (fileSize['width']) {
        options = {
          maxWidth: maxSize,
          canvas: true,
        };
      } else {
        options = {
          maxHeight: maxSize,
          canvas: true,
        };
      }

      if (data.exif) {
        options.orientation = data.exif.get('Orientation');
      }

      loadImage(file, (canvas) => {
        if (canvas.type === 'error') {
          reject(new Error('failed to resize image'));
          return;
        }

        const format = file.type;
        const name = file.name;
        const imageUri = canvas.toDataURL(format);
        const imageFile = toFile(imageUri, format, name);
        resolve({
          imageFile
        });
      }, options);
    });
  });
};

/**
 * 画像が最大値を超えているか判定する
 *
 * @param {File} file ファイルオブジェクト
 * @param {number} maxSize 最大縦横サイズ
 * @returns {boolean}
 */
const isOverImageSize = async (file, maxSize) => {
  const res = await loadImageSize(URL.createObjectURL(file));

  return res.width > maxSize || res.height > maxSize;
};

/**
 * imageのサイズをonloadにより取得するためのメソッド
 *
 * @param {string} url ファイルurl
 * @returns {Promise} promiseオブジェクト
 */
const loadImageSize = (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = () => reject(new Error('failed to load image size'));
    image.src = url;
  });
};

/**
 * 画像が最大値を超えているサイズを取得する
 *
 * @param {File} file ファイルオブジェクト
 * @returns {Object} width or height
 */
const getEitherImageSize = async (file) => {
  const res = await loadImageSize(URL.createObjectURL(file));
  if (res.width > res.height) {
    return {'width': res.width};
  } else {
    return {'height': res.height};
  }
};

/**
 * ファイルのリサイズハンドラー
 *
 * @param {File} file ファイルオブジェクト
 * @param {number} maxSize 最大縦横サイズ
 * @return {File|null} imageFile-リサイズしたfile
 */
export const imageChangeHandler = async (file, maxSize) => {
  try {
    const isOverSize = await isOverImageSize(file, maxSize);
    if (isOverSize) {
      const fileSize = await getEitherImageSize(file);
      const { imageFile } = await resizeImage(file, fileSize, maxSize);
      return imageFile;
    } else {
      return file;
    }
  } catch (e) {
    // 処理失敗時にはSentryへログ送信
    captureException(e);
  }

  return null;
};