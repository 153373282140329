export const getBlobUrls = (files) => {
  return Array.from(files).map((file) => window.URL.createObjectURL(file));
};

/**
 * 画像を除外したファイル
 *
 * 引数に指定したファイルデータ配列の中で、
 * 画像形式でないファイルのみを配列として返却する。
 *
 * @param {array} files - ファイル配列
 * @returns {any[]}
 */
export const excludeImages = files => {
  return Array.from(files).filter(file =>
      file.type !== 'image/png' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/gif'
  );
};
