import { option, fetchData } from '../utils/fetch';
import { deleteQuestionImage, successGetQuestionImages } from '../actions/images';

/**
 *
 * 指定した名前のファイルを取得
 *
 * @param url アクセスURL
 * @returns {function(*): Promise<string | never>}
 */
export const downloadFile = async url => {
  const op = await option.image();
  return fetchData(url, op)
    .then(response => {
      if(response.ok) {
        return response.blob();
      } else {
        throw Error();
      }
    })
    .then(blob => window.URL.createObjectURL(blob))
    .catch(() => null);
};

/**
 * 質問画像の取得
 *
 * 画像取得 api をリクエストし、画像の blob url を保管する。
 * 一度取得した画像データを複数コンポーネントでキャッシュとして
 * 利用するための処理。
 *
 * @param {number} questionId - 質問ID
 * @param {array} photoUrls - 画像 api URL 文字列
 * @returns {Function}
 */
export const getQuestionImages = (questionId, photoUrls) => async dispatch => {
  dispatch(deleteQuestionImage(questionId));
  for(const photoUrl of photoUrls) {
    const blobUrl = await downloadFile(photoUrl);
    dispatch(successGetQuestionImages(questionId, photoUrl, blobUrl));
  }
};
