export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUP_DATA = 'GET_GROUP_DATA';
export const REQUEST_POST_JOIN_GROUP = 'REQUEST_POST_JOIN_GROUP';
export const POST_JOIN_GROUP = 'POST_JOIN_GROUP';
export const REQUEST_GET_GROUP_REQUESTS = 'REQUEST_GET_GROUP_REQUESTS';
export const GET_GROUP_REQUESTS = 'GET_GROUP_REQUESTS';
export const REQUEST_GET_GROUP_REQUESTS_WITH_CURSOR = 'REQUEST_GET_GROUP_REQUESTS_WITH_CURSOR';
export const GET_GROUP_REQUESTS_WITH_CURSOR = 'GET_GROUP_REQUESTS_WITH_CURSOR';
export const REQUEST_GET_GROUP_USERS = 'REQUEST_GET_GROUP_USERS';
export const GET_GROUP_USERS = 'GET_GROUP_USERS';
export const REQUEST_GET_GROUP_USERS_WITH_CURSOR = 'REQUEST_GET_GROUP_USERS_WITH_CURSOR';
export const GET_GROUP_USERS_WITH_CURSOR = 'GET_GROUP_USERS_WITH_CURSOR';
export const REQUEST_CONFIRM_GROUP_REQUEST = 'REQUEST_CONFIRM_GROUP_REQUEST';
export const CONFIRM_GROUP_REQUEST = 'CONFIRM_GROUP_REQUEST';
export const VALIDATE_CONFIRM_GROUP_REQUEST = 'VALIDATE_CONFIRM_GROUP_REQUEST';
export const REQUEST_DELETE_GROUP_USER = 'REQUEST_DELETE_GROUP_USER';
export const DELETE_GROUP_USER = 'DELETE_GROUP_USER';
export const SET_NOTIFY_GENERAL = 'SET_NOTIFY_GENERAL';
export const SET_NOTIFIES_MANAGER = 'SET_NOTIFIES_MANAGER';
export const RESET_NOTIFIES = 'RESET_NOTIFIES';
export const RESET_GROUPS = 'RESET_GROUPS';

// 全体グループのID, ID変更時に修正が必要
export const PUBLIC_GROUP_ID = 'PxJEXk1DJHqc';
export const SKIN_QA_GROUP_ID = 'ol7RVBZZCI6z';
