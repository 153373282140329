import * as React from 'react';
import * as styles from './modal.module.css';

/**
 * モーダルコンポーネント
 *
 * @reactProps {boolean} isOpen - 開いているかどうか
 * @reactProps {object} children - 内側に表示するコンポーネント
 * @reactProps {object} style - スタイルオブジェクト
 * @reactProps {function} close - モーダルを閉じる関数は親コンポーネントから渡す
 */
class Modal extends React.Component {
  constructor(props){
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.setScrollLock(true);
  }

  componentWillUnmount() {
    this.setScrollLock(false);
  }

  closeModal() {
    this.setScrollLock(false);
    this.props.close();
  }

  /**
   * 背景スクロールロックの設定
   * @param {boolean} isSet - 設定フラグ
   */
  setScrollLock(isSet) {
    const style = document.documentElement.style;
    if(isSet) {
      style.overflow = 'hidden';
    } else {
      style.overflow = 'scroll';
    }
  }

  render() {
    const { isOpen = true, confirm = false, children, style } = this.props;
    return (
      <div>
        <div className={isOpen ? styles.center : `${styles.center} ${styles.isHidden}`}>
          <div className={styles.mask} onClick={this.closeModal} />
          <div className={confirm ? `${styles.wrapper} ${styles.confirm}` : `${styles.wrapper} ${styles.modal}`} style={style}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
