/**
 * URL配列の取得
 *
 * 引数に指定した文字列から URL 文字列のみを抜き出し、
 * URLを配列にして返却する。
 * URLが存在しない場合には null を返却し、テキストが
 * 空の場合には空の配列を返却する。
 *
 * @param {string} text - テキスト
 * @returns {[<string>]|null} - URL文字列配列
 */
export const getUrls = text => {
  if(!text) {
    return [];
  }
  return text.match(regexUrl());
};

/**
 * 単体URLの取得
 *
 * 引数に指定した質問IDについて、単体表示URLを取得する。
 * (例: 質問IDが 1234 の場合には 'https://qa.antaa.jp/threadId/1234')
 * もし、質問IDが null | undefined | 空文字 の場合にはホームURLを返却する。
 *
 * @param {string|number} questionId - 質問ID(文字列|数値)
 * @returns {string} - URL文字列
 */
export const getSingleUrl = questionId => {
  const protocol = window.location.protocol + '//';
  const host = window.location.host;
  return questionId
    ? protocol + host + '/threadId/' + questionId
    : protocol + host;
};

export const regexUrl = () => {
  return /(https?:\/\/[a-zA-Z_0-9-.!'()*;/?:@&=+$,%#]+)/gi;
};
