import * as c from '../constants/images';

const initialState = {
  questions: {},
};

export default function images(state = initialState, action) {

  const overwrite = (array, urls) => {
    const removed = array.filter(e => !urls.photoUrl.includes(e.photoUrl));
    return removed.concat(urls);
  };

  switch(action.type) {
    case c.GET_QUESTION_IMAGES:
      return {
        questions: {
          ...state.questions,
          [action.payload.questionId]: state.questions[action.payload.questionId]
            ? overwrite(state.questions[action.payload.questionId], action.payload.urls)
            : [{...action.payload.urls}],
        },
      };

    case c.DELETE_QUESTION_IMAGE:
      return {
        questions: {
          ...state.questions,
          [action.payload.questionId]: undefined,
        },
      };
    default:
      return state;
  }
}