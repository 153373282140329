import { baseURL, isSuccess, option, fetchData } from '../utils/fetch';
import { failureGetDepartments, successGetDepartments } from '../actions/departments';

/**
 * 診療科目一覧の取得
 *
 * 科目一覧を取得して保管する。
 *
 * @return {function(*): Promise<any | never>}
 */
export const getDepartments = () => async dispatch => {
  const op = await option.json();
  return fetchData(baseURL() + 'departments', op)
    .then(response => isSuccess(response))
    .then(json => {
      const departmentsMap = new Map();
      json.departments.forEach(value =>
        departmentsMap.set(value['departmentId'], value)
      );
      return {
        departments: json.departments,
        departmentsMap
      };
    })
    .then(items => dispatch(successGetDepartments(items)))
    .catch(error => dispatch(failureGetDepartments(error.message)));
};
