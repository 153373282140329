import 'url-search-params-polyfill';
import * as arrayUtil from './array';

export const getHostnameFromString = (path) => {
  if (!path) return '';
  const result = path.replace(/\\/g, '/').match(/\/\/([^/]*)/);
  if (!result) return '';
  return result[1];
};

/**
 * エンコード
 * @param {string} name - プロパティ名
 * @param {string} string - エンコード対象文字列
 * @returns {string|null}
 */
export const encode = (name, string) => {
  if(!string) {
    return null;
  }
  const params = new URLSearchParams();
  params.append(name, string);
  return params.toString();
};

/**
 * エンコード
 * @param {array} items - パラメータ項目オブジェクト配列
 * @returns {string|null}
 */
export const encodes = items => {
  if(arrayUtil.isEmpty(items)) {
    return null;
  }
  const params = new URLSearchParams();
  items.forEach(item => {
    if(item.name && item.string) {
      params.append(item.name, item.string);
    }
  });
  return params.toString();
};

/**
 * デコード
 * @param {string} string - デコード対象文字列
 * @returns {string}
 */
export const decode = string => {
  if(!string) {
    return '';
  }
  return string
    .replace(/[ +]/g, '%20')
    .replace(/(%[a-f0-9]{2})+/ig, function(match) {
      return decodeURIComponent(match);
    });
};

/**
 * パラメータ値の取得
 * @param {string} name - パラメータ名
 * @param {string} params - URLパラメータ文字列
 * @returns {string|null}
 */
export const getParamValue = (name, params) => {
  const searchParams = new URLSearchParams(params);
  return searchParams.get(name);
};

/**
 * 空文字を含む数値の変換
 *
 * 引数に渡された文字列の数('123')を数値(123)に変換して返却する。
 * もし、空文字('')だった場合にはそのまま返却する。
 * '' を 0 と変換しないための関数。
 *
 * @param value {string} 数値文字
 * @returns {*}
 */
export const convertEmptyNumber = value => value === '' ? '' : Number(value);

/**
 * 日付文字列(YYYY-MM-DD)の変換
 *
 * 引数に指定した文字列を `YYYY-MM-DD` 書式にして返す。
 *
 * @param {string} yyyymmdd - 年月日(YYYYMMDD)
 * @returns {string} 年月日(YYYY-MM-DD)
 */
export const getYYYY_MM_DD = (yyyymmdd) => {
  const year = yyyymmdd.slice(0, 4);
  const month = yyyymmdd.slice(4, 6);
  const day = yyyymmdd.slice(6, 8);
  return `${year}-${month}-${day}`;
};

/**
 * UnixTimeへ変換
 *
 * 引数に指定した ISO8601形式(例:'2019-07-16T11:49:24+09:00')を
 * 1970年1月1日00:00:00(UTC) からの秒単位の経過時間を表す数値に
 * 丸め込んで返却する。
 * また、引数が '', null, undefined の場合には null を返却する。
 *
 * @param {string|null} dateString - 日付文字列
 * @returns {number|null} - UnixTime
 */
export const parseToUnixTime = dateString => {
  return !dateString
    ? null
    : Math.round(Date.parse(dateString) / 1000);
};